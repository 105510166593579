.App {
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

img {
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
}

.iubenda-text {
  text-decoration: none;
  color: white;
}

#textEffect {
  padding-top: 20%;
  font-size: 100%;
}

ul {
  list-style-type: none;
}

.zoom {
  transition: transform 0.4s;
  /* Animation */
}

.zoom:hover {
  transform: scale(1.1);
}

@font-face {
  font-family: "Inter-Thin";
  src: url("font/Inter-ExtraLight.ttf") format("truetype");
}
